<template>
  <b-container class="content contact">
    <h1>Contact</h1>
    <p>
      This is a private project to promote the STAC specification. It is operated by:
    </p>
    <p>
      Matthias Mohr<br />
      Grevener Str. 313<br />
      48159 Münster<br />
      Germany
    </p>
    <p>
      You can contact me via e-mail at m.mohr@uni-muenster.de.<br />
      For bugs and suggestions you can refer to the <a href="https://github.com/stac-utils/stac-index/issues" target="_blank">project's issue tracker on GitHub</a>.
    </p>
  </b-container>
</template>

<script>
export default {
  name: 'Contact'
}
</script>